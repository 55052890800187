body {
  background-color: #262627;
  font-size: 14px;
}
a {
  text-decoration: none;
}
.link {
  cursor: pointer;
}
.login-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-holder .login-outer {
  flex-grow: 1;
  padding: 15px;
}
.login-holder .login-outer .login-inner {
  max-width: 500px;
  border: 2px solid white;
  border-radius: 10px;
  padding: 50px 30px;
  margin: 0 auto;
}
.login-holder .login-outer .login-inner label {
  color: white;
}
.input {
  position: relative;
  margin-bottom: 15px;
}
.input input {
  margin-bottom: 0 !important;
}
.input .invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
  display: block;
}
.selector-holder {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
}
.selector-holder .selector-button {
  flex-grow: 1;
  text-align: center;
  min-width: 240px;
  height: 200px;
  border-radius: 10px;
  border: 2px solid white;
  display: flex;
}
.selector-holder .selector-button a {
  margin: auto;
  font-size: 30px;
  color: white;
  font-weight: 800;
}
.button-xl {
  padding: 30px 20px !important;
  max-height: unset !important;
  font-size: 30px;
  width: 280px;
  text-align: center;
  border-radius: 100px !important;
}
button,
.button,
.button:hover {
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  min-width: 65px;
  border: 0;
  outline: 0;
  background-color: #FEA736;
  border-radius: 30px;
  display: inline-block;
  max-height: 31px;
}
label {
  display: block;
  margin-bottom: 5px;
}
input:not([type=file]) {
  border: 1px solid #FEA736;
  border-radius: 10px;
  padding: 3px 6px;
  margin-bottom: 15px;
  width: 100%;
}
input[type=file] {
  color: #FEA736;
}
select {
  border: 1px solid #FEA736;
  border-radius: 10px;
  padding: 3px 6px;
  width: 100%;
  margin-bottom: 15px;
}
.header {
  height: 60px;
  background-color: #313131;
  margin-bottom: 50px;
}
.header .my-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header img {
  padding: 5px;
  max-width: available;
  height: 60px;
}
.panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px 40px;
}
.title {
  color: #FEA736;
}
.article__item {
  margin-bottom: 1rem;
}
.article__item .short p {
  margin-bottom: 0.5rem;
}
.article__item img {
  margin-bottom: 10px;
  border: 5px solid #D8D8D8;
  border-radius: 5px;
}
.event__item {
  cursor: pointer;
}
.event__item img {
  margin-bottom: 10px;
  border: 5px solid #D8D8D8;
  border-radius: 5px;
}
.event__item .event-title {
  font-size: 18px;
  font-weight: bold;
  color: #FEA736;
}
.event__item .event-date {
  font-size: 14px;
  font-weight: bold;
  color: #222222;
}
.page-item.active .page-link {
  background-color: #222;
  border-color: transparent;
}
.page-item .page-link {
  color: #222;
}
.event__page .event-head {
  align-items: flex-end;
}
.event__page .event__title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
}
.event__page .event__detail {
  font-weight: 400;
  font-size: 20px;
}
.voucher__send {
  height: 100%;
  margin-top: 25px;
  gap: 10px;
  align-items: center;
}
.voucher__send input,
.voucher__send select {
  margin-bottom: 0;
}
.voucher__send .voucher__button {
  gap: 10px;
}
.row-block .row {
  margin-bottom: 10px;
}
.row-block .row.selected {
  font-weight: bold;
  background-color: #FEA736;
}
.row-block .row.selected button {
  background: black;
}
.row-block .row.head {
  color: #FEA736;
  font-weight: bold;
}
.row-block .ticket-row {
  cursor: pointer;
  padding: 5px 0;
  align-items: center;
}
.row-block .ticket-row.active {
  padding-bottom: 10px;
}
.row-block .ticket-row.active .ticket-holder {
  box-shadow: 1px 1px 5px rgba(254, 167, 54, 0.7);
  transition: all 0.3s;
  border-radius: 10px;
}
.row-block .ticket-row.active .voucher-row {
  font-weight: bold;
}
.row-block input {
  margin: 0;
}
.promoter .qr-code {
  height: 180px;
  min-width: 180px;
}
.promoter .promoter-image {
  max-width: 100%;
  max-height: 180px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.nav-button {
  background-color: transparent !important;
  border-color: transparent !important;
  outline: none;
  box-shadow: none;
}
.dropdown-item:active {
  background-color: #FEA736;
}
.filter-open {
  font-weight: bold;
}
.font-weight-bold {
  font-weight: bold;
}
.filter-holder {
  margin-bottom: 10px;
}
.subtitle {
  margin-bottom: 10px;
  font-weight: bold;
  color: #FEA736;
  display: block;
}
.profile-page label {
  color: #FEA736;
}
.performance-grid {
  display: grid;
}
.performance-grid div {
  padding: 5px;
}
.performance-grid .performance-grid__header {
  font-weight: bold;
  border-bottom: 1px solid grey;
}
.transaction-grid {
  display: grid;
}
.transaction-grid div {
  padding: 5px;
}
.transaction-grid .performance-grid__header {
  font-weight: bold;
  border-bottom: 1px solid grey;
}
.grid-col-4 {
  grid-template-columns: repeat(4, auto);
}
.grid-col-5 {
  grid-template-columns: repeat(5, auto);
}
.grid-col-10 {
  grid-template-columns: repeat(10, auto);
}
.grid div {
  padding: 5px;
}
.grid .grid__header {
  font-weight: bold;
  border-bottom: 1px solid grey;
}
.qr tr {
  height: 4px !important;
}
.qr tr td {
  width: 4px !important;
}
/**********************/
/**** Check in app ****/
/**********************/
.check-in {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
}
.check-in .ticket-data {
  font-size: 20px;
  flex-grow: 1;
  color: white;
}
.check-in .ticket-data button {
  padding: 15px 30px;
  max-height: none;
}
.ticket-button {
  text-align: center;
  flex-grow: 1;
}
.mobile,
.mobile-block {
  display: none;
}
hr {
  background-color: white;
}
.hr-grey {
  background-color: grey;
}
.qr-reader div {
  padding-top: 75px;
}
@media (max-width: 767px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inline-block;
  }
  .mobile-block {
    display: block;
  }
  .performance-grid,
  .transaction-grid {
    display: block;
  }
  .filter-holder {
    border: 1px solid #FEA736;
    border-radius: 10px;
    padding: 10px;
  }
  .ticket-row {
    border: 1px solid #FEA736;
    border-radius: 10px;
    margin: 0;
    margin-bottom: 10px;
  }
  body {
    height: 100vh;
    display: flex;
  }
  .panel {
    padding: 25px 10px;
  }
  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .app-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .header {
    margin-bottom: 25px;
  }
  .login-holder .login-outer {
    max-width: 500px;
    width: auto;
  }
}
.alert-checkin {
  position: relative !important;
  width: 100%;
  display: block;
}
.button-md {
  padding: 15px 10px !important;
  max-height: unset !important;
  font-size: 21px;
  width: 270px;
  text-align: center;
  border-radius: 100px !important;
}
.checkin-fa-left {
  font-size: 27px;
  vertical-align: text-bottom;
  margin-right: 10px;
}
.checkin-fa-right {
  font-size: 27px;
  vertical-align: text-bottom;
  margin-left: 10px;
}
.checkin-events .event-date {
  color: white;
}
.qr-zoom {
  top: 0;
  display: none;
}
.qr-zoom.show {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transform: scale(2) translate(0%, 0%);
  transform-origin: center center;
}
.qr-zoom-holder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 10000;
}
.half-width {
  width: 50%;
}
.max-half-width {
  max-width: 50%;
}
.attachment-link {
  font-weight: bold;
  color: #FEA736;
}
.attachment-fa {
  color: #FEA736;
  margin-right: 10px;
}
.input-block {
  position: relative;
  margin-bottom: 15px;
}
.input-block .error {
  position: absolute;
  left: 0;
  width: 100%;
  top: 100%;
}
.profile-block {
  margin-bottom: 15px;
}
.profile-block .collapse-header {
  padding: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  width: 100%;
}
.profile-block .collapse-body {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 1px solid white;
  border-top: 0;
}
.btn-disabled {
  background-color: #666666 !important;
  cursor: not-allowed !important;
}
.voucher-ticket-row {
  margin: 0;
  margin-bottom: 1rem;
}
.sent-voucher-tickets {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid transparent;
}
.voucher-tickets-head {
  border-bottom: 1px solid #bbbbbb;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
@media (max-width: 767px) {
  .sent-voucher-tickets {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    border: 0;
  }
  .voucher-ticket-row {
    margin: 0;
    margin-bottom: 1rem;
  }
}
span.mobile {
  margin-right: 5px;
}
.data {
  color: white;
}
