.alert {
    margin: 0 20px;
    z-index: 100;
}

.alert > .alert__body {
    justify-content: space-between;
}
.alert .alert__message{
    line-break: anywhere;
}
.alert .alert__title {
    font-weight: bold;
    font-size: 16px;
    padding-right:20px;
    line-break: anywhere;
}

.alert{
    position: fixed !important;
    right: 12px !important;
    padding: 10px !important;
    border-radius: 5px !important;
    margin:0;
    display: flex;
    margin-left: 12px !important;
}

.alert.error {
    color: white;
    background: #dc3545;
}

.alert.warning {
    color: black;
    background-color: #ffc107;
}

.alert.success {
    color: white;
    background-color: #198754;
}
